<template>
  <div v-if="show" class="pt-3">
    <v-divider />
    <v-card v-for="(i, iI) in dispatchData" :key="'dispatch-item-' + iI" class="px-4 py-2 rounded-lg my-2" outlined @click.prevent="$emit('show', i)">
      <div class="d-flex fill-width align-center justify-space-between text-uppercase font-weight-bold primary--text py-1 subtitle-1">
        <div>
          <div class="d-flex align-center" style="line-height: 120%;">
            <v-icon color="orange" class="mr-2">
              mdi-cube-send
            </v-icon>
            <div class="">
              #{{ i.id }} {{ i.dispatch_vehicle_name ? (i.dispatch_vehicle_name + ' ' + i.dispatch_vehicle_plat_number) : i.dispatch_vehicle_notes }}
              <div v-if="parseInt(i.vendor)" class="d-flex align-center secondary--text" style="font-size: .7rem;">
                <v-icon small class="mr-1" color="secondary" style="font-size: .7rem;">
                  mdi-information-outline
                </v-icon>
                <div>
                  {{ i.vendor_code || '~' }}
                </div>
                <v-icon x-small>
                  mdi-circle-small
                </v-icon>
                <div>
                  AWB : <strong>{{ i.vendor_awb || '~' }}</strong>
                </div>
                <v-icon x-small>
                  mdi-circle-small
                </v-icon>
                <div>
                  {{ $price(i.price || 0) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <span v-if="parseInt(i.manifest_count)" class="caption font-weight-bold">
          {{ parseInt(i.manifest_count) }} MANIFEST
        </span>
        <span v-else @click.stop>
          <v-icon color="red" small class="" @click.prevent="DeleteDispatch(i.id)">
            mdi-close
          </v-icon>
        </span>
      </div>
      <div class="d-flex align-center justify-space-between">
        <v-chip x-small color="primary">
          FROM : {{ i.store_origin_name }}
        </v-chip>
        <div class="px-2" style="max-width: 70%;min-width: 50%;">
          <div v-if="i.position" class="d-flex fill-width justify-space-between">
            <v-icon x-small>
              mdi-transfer-right
            </v-icon>
            <div v-if="ParsePosition(i.position).departure && !ParsePosition(i.position).arrival" class="d-flex align-center text-uppercase">
              {{ ParsePosition(i.position).position }}
              <v-icon x-small class="mx-2">
                mdi-chevron-right
              </v-icon>
              {{ ParsePosition(i.position).destination_city }}
            </div>
            <div v-else class="text-uppercase">
              {{ ParsePosition(i.position).position }}
            </div>
            <v-icon x-small>
              mdi-transfer-right
            </v-icon>
            <!-- -> {{ i.position }} -->
          </div>
          <div v-else class="d-flex fill-width justify-center grey--text lighten-5 caption">
            - STANDBY -
          </div>
        </div>
        <v-chip x-small color="primary">
          TO : {{ i.store_destination_name }}
        </v-chip>
      </div>
      <v-divider v-if="i.position" class="my-2" />
      <div v-if="parseInt(i.manifest_unit_quantity_sum) || parseInt(i.manifest_unitnon_quantity_sum)" class="d-flex align-center justify-space-between caption">
        <div v-if="parseInt(i.manifest_unit_quantity_sum)">
          <v-chip x-small class="font-weight-bold">
            {{ i.manifest_unit_weight || '~' }}KG, {{ i.manifest_unit_quantity_sum }} UNIT in {{ i.manifest_unit_quantity }} PKG
          </v-chip>
        </div>
        <v-divider v-if="parseInt(i.manifest_unit_quantity_sum) && parseInt(i.manifest_unitnon_quantity_sum)" vertical />
        <div v-if="parseInt(i.manifest_unitnon_quantity_sum)">
          <v-chip x-small class="font-weight-bold">
            {{ i.manifest_unitnon_weight || '~' }}KG, {{ i.manifest_unitnon_quantity_sum }} ITEM in {{ i.manifest_unitnon_quantity }} PKG
          </v-chip>
        </div>
      </div>
      <div v-if="parseInt(i.manifest_count)" @click.stop>
        <v-divider class="my-2" />
        <div v-if="i.manifest_list.length">
          <v-card v-for="(m, iM) in i.manifest_list" :key="'manifest-list' + i.id + '-' + iM" outlined class="px-4 py-2 mb-2">
            <div class="d-flex justify-space-between fill-width align-center">
              <div class="caption grey--text">
                <span>MANIFEST/{{ m.store_origin_code }}/{{ m.store_destination_code }}{{ m.vendor_code ? ('/' + m.vendor_code) : '' }}/{{ m.id }}</span>
              </div>
              <div class="d-flex align-center justify-end caption fill-width">
                <span>
                  {{ m.shipping_total }} TTK
                </span>
                <v-icon small>
                  mdi-circle-small
                </v-icon>
                <div v-if="parseInt(m.unit_quantity_sum)">
                  <span class="d-flex align-center">
                    {{ m.unit_weight || '~' }}KG
                    <v-icon small>
                      mdi-circle-small
                    </v-icon>
                    {{ m.unit_quantity_sum }} UNIT in {{ m.unit_quantity }} PKG
                  </span>
                </div>
                <v-divider v-if="parseInt(m.unit_quantity_sum) && parseInt(m.unitnon_quantity_sum)" vertical class="mx-1" />
                <div v-if="parseInt(m.unitnon_quantity_sum)">
                  <span class="d-flex align-center">
                    {{ m.unitnon_weight || '~' }}KG
                    <v-icon small>
                      mdi-circle-small
                    </v-icon>
                    {{ m.unitnon_quantity_sum }} ITEM in {{ m.unitnon_quantity }} PKG
                  </span>
                </div>
              </div>
            </div>
            <div class="d-flex fill-width justify-space-between">
              <div class="d-flex flex-column">
                {{ m.store_origin_name || '-' }}
                <div class="caption">
                  {{ m.store_origin_city || '-' }}
                </div>
                <div class="caption d-flex align-center">
                  <v-icon x-small class="mr-1" color="pink">
                    mdi-clock-time-five-outline
                  </v-icon>
                  {{ m.manifest_departure ? $localDT(m.manifest_departure, 'datetime') : '~' }}
                </div>
              </div>
              <v-icon small>
                mdi-chevron-right
              </v-icon>
              <div class="d-flex flex-column">
                {{ m.store_destination_name || '-' }}
                <div class="caption">
                  {{ m.store_destination_city || '-' }}
                </div>
                <div class="caption d-flex align-center">
                  <v-icon x-small class="mr-1" color="pink">
                    mdi-clock-time-five-outline
                  </v-icon>
                  <span v-if="m.manifest_arrival">
                    {{ $localDT(m.manifest_arrival, 'datetime') }}
                  </span>
                  <span v-else>
                    ~
                  </span>
                </div>
              </div>
            </div>
          </v-card>
        </div>
        <v-btn v-else :loading="i.manifest_loading" text block class="pa-2" @click.prevent="MANIFEST_DISPATCH_LIST(i.id)">
          - LOAD {{ (parseInt(i.manifest_count) || 0) }} MANIFEST DATA -
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    show: true,
    dispatchData: []
  }),
  watch: {
    items: {
      immediate: true,
      handler (v) {
        this.dispatchData = (v || []).map((r) => {
          r.manifest_list = []
          r.manifest_loading = false
          return r
        })
      }
    }
  },
  methods: {
    DeleteDispatch (id) {
      if (!confirm('Delete dispatch ?')) {
        return false
      }
      this.$store.dispatch('logistic/DISPATCH_DELETE', id)
        .then((res) => {
          console.log(res)
        })
      this.$emit('reload', true)
    },
    ParsePosition (str) {
      const data = (str || '').split('<|>')
      const deparr = data[0].split('|')
      const origin = data[1].split('|')
      const destination = data[2].split('|')
      const position = (!deparr[0] && !deparr[1])
        ? ('PREPARE : ' + origin[0])
        : (deparr[0] && !deparr[1])
          ? origin[0]
          : destination[0]
      const result = {
        departure: deparr[0] || '',
        arrival: deparr[1] || '',
        origin_city: origin[0] || '',
        origin_type: origin[1] || '',
        origin_province: origin[2] || '',
        destination_city: destination[0] || '',
        destination_type: destination[1] || '',
        destination_province: destination[2] || '',
        position
      }
      return result
    },
    MANIFEST_DISPATCH_LIST (dispatchID) {
      const getDispatchIndex = this.dispatchData.findIndex(r => parseInt(r.id) === parseInt(dispatchID))
      if (getDispatchIndex > -1) {
        this.dispatchData[getDispatchIndex].manifest_loading = true
        this.$store.dispatch('logistic/MANIFEST_GET', '?dispatch=' + (dispatchID) + '&limit=1000').then((res) => {
          if (res.status) {
            this.dispatchData[getDispatchIndex].manifest_loading = false
            this.dispatchData[getDispatchIndex].manifest_list = (res.data.data.data || [])
            this.show = false
            setTimeout(() => {
              this.show = true
            }, 5)
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
</style>
